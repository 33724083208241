import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import { Container, Row, Col } from 'reactstrap';

const CardSideDishesSpecial = ({ title, products, type}) => {
    const renderSpecialDrink = () => {
        switch (type) {
            case 'entrances' :
                return 'PAPAS FRITAS'
            case 'drinks':
                return 'CERVEZAS'
            default: return null;
        }
    }

    return (
        <Container tag="article" className={`p-5 mt-3 mt-md-5 card-special card-special-${type}`}>
            <Row>
                <Col className="text-center text-md-start">
                    <h2>{title}</h2>
                </Col>
            </Row>
            <Row className="text-center text-md-start">
                <Col md="6">
                    <h4 className="product-title-type">{renderSpecialDrink()}</h4>
                    {
                        products.map(product => product.active === "1" && product.type && (
                            <Row tag="article">
                                <Col md="7">
                                    <p className="product-title-variation mb-0">{product.title}</p>
                                    {product.description && (
                                        <p className="product-description">{product.description}</p>
                                    )}
                                </Col>
                                <Col md="5">
                                    <p className="price-tag">${product.price}</p>
                                </Col>
                            </Row>
                        ))
                    }
                </Col>
                <Col md="6">
                    {
                        products.map(product => product.active === "1" && !product.type && (
                            <Row tag="article">
                                <Col md="7">
                                    <p className="product-title mb-0">{product.title}</p>
                                    {product.description && (
                                        <p className="product-description">{product.description}</p>
                                    )}
                                </Col>
                                <Col md="5">
                                    <p className="price-tag">${product.price}</p>
                                </Col>
                            </Row>
                        ))
                    }
                </Col>
            </Row>
        </Container>
    );
}

CardSideDishesSpecial.prototype = {
    title: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(Object).isRequired,
    type: PropTypes.string.isRequired
}

export default CardSideDishesSpecial;