import React, {useEffect, useState} from 'react';
import axios from 'axios';

import CardSideDishes from '../../components/CardSideDishes/CardSideDishes';
import CardSideDishesSpecial from '../../components/CardSideDishesSpecial/CardSideDishesSpecial';
import Banner from "../../components/Banner/Banner";

const Entrances = () => {
    const [entrances, setEntrances] = useState([]);
    const [salads, setSalads] = useState([]);
    const [toastes, setToastes] = useState([]);

    useEffect(() => {
        const fetchEntrancesData = async () => {
            const response = await axios.get("https://hutch.com.ar/api/entrances.php");
            setEntrances(response.data);
        }
        const fetchSaladsData = async () => {
            const response = await axios.get("https://hutch.com.ar/api/salads.php");
            setSalads(response.data);
        }
        const fetchToastesData = async () => {
            const response = await axios.get("https://hutch.com.ar/api/toastes.php");
            setToastes(response.data);
        }
        fetchEntrancesData()
        fetchSaladsData()
        fetchToastesData()
    }, [])

    return (
        <main>
            <Banner page="entrances" title="ENTRADAS Y ENSALADAS" />
            {entrances.length > 0 && (
                <CardSideDishesSpecial
                    title="ENTRADAS"
                    products={entrances}
                    type="entrances"
                />
            )}
            {salads.length > 0 && (
                <CardSideDishes
                    title="ENSALADAS"
                    products={salads}
                    type="salads"
                />
            )}
            {toastes.length > 0 && (
                <CardSideDishes
                    title="TOSTADOS"
                    products={toastes}
                    type="toastes"
                />
            )}
        </main>
    );
}

export default Entrances;