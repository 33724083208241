import * as React from "react"
const SvgComponent = ({width = 50, height = 50}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} data-name="Layer 1" viewBox="0 0 66.19 66.22">
        <path
            d="M31.01 0h4.14c.17.04.33.09.5.11 5.16.39 9.98 1.87 14.38 4.57 8.55 5.26 13.81 12.83 15.66 22.73.22 1.2.34 2.42.51 3.64v4.14c-.04.19-.08.37-.11.56-.22 1.47-.35 2.95-.66 4.4-3.1 14.63-16.05 25.53-30.96 26.04-8.7.3-16.47-2.3-23.02-8.07C2.93 50.62-.83 41.09.15 29.79c.63-7.2 3.51-13.51 8.36-18.86C13.62 5.31 19.93 1.8 27.44.5c1.18-.21 2.38-.34 3.57-.5Zm-17.2 52.69c.28-.06.46-.09.64-.14 3.1-.81 6.2-1.63 9.31-2.41.33-.08.77-.03 1.09.13 3.64 1.79 7.46 2.47 11.48 1.95C47.35 50.8 55.06 40.51 53.37 29.5c-1.67-10.87-12.04-18.33-22.9-16.46C17.1 15.34 9.94 30 16.32 41.97c.16.3.2.75.11 1.08-.84 3.18-1.72 6.35-2.62 9.64Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
        <path
            d="M18.57 48c.47-1.73.89-3.4 1.39-5.03.21-.69.15-1.23-.24-1.86-6.03-9.97-.16-22.79 11.32-24.78 9.46-1.64 18.36 5.27 19.14 14.88.83 10.08-7.5 18.52-17.58 17.8-2.62-.19-5.05-.99-7.33-2.3a1.45 1.45 0 0 0-1-.14c-1.76.42-3.5.89-5.25 1.35-.12.03-.24.05-.45.09Zm20.4-5.91c.32-.02.62 0 .92-.06 2.3-.47 4.01-1.79 3.92-4.26 0-.25-.18-.62-.39-.72-1.32-.68-2.66-1.31-4-1.94-.47-.22-.85-.12-1.17.33-.44.61-.94 1.17-1.41 1.76-.3.38-.65.43-1.09.25-2.91-1.16-5.12-3.11-6.73-5.78-.23-.39-.23-.7.06-1.05.41-.5.83-1 1.15-1.55.16-.28.24-.72.13-1.01a73.11 73.11 0 0 0-1.6-3.87c-.11-.26-.33-.53-.58-.65-.67-.35-2.13-.08-2.67.45-1.54 1.5-2.08 3.32-1.65 5.42.37 1.8 1.38 3.27 2.46 4.71 2.09 2.77 4.52 5.15 7.73 6.58 1.57.7 3.15 1.4 4.91 1.41Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
    </svg>
)
export default SvgComponent
