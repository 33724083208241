import React from 'react';

import './styles.scss';

import { Container, Row, Col } from 'reactstrap';

import BannerHome from "../../components/BannerHome/BannerHome";
import BestSellerCard from "../../components/BestSellerCard/BestSellerCard";

import BurgerIcon from "../../components/Icons/BurgerIcon";
import FlyingCakeIcon from "../../components/Icons/FlyingCakeIcon";
import PeopleIcon from "../../components/Icons/PeopleIcon";


const Home = () => {
    return (
        <main className="home">
            <BannerHome/>
            <Container fluid className="best-sellers py-5">
                <Container>
                    <Row className="pb-4">
                        <Col className="text-center">
                            <h2>LAS MAS BUSCADAS</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="4">
                            <BestSellerCard stars={4} title="CHB" imgName="2" recomendationsQuantity={2754} />
                        </Col>
                        <Col md="6" lg="4" className="pt-4 pt-md-0">
                            <BestSellerCard stars={5} title="CRISPY ONION" imgName="9" recomendationsQuantity={5054} />
                        </Col>
                        <Col lg="4" className="pt-4 pt-lg-0">
                            <BestSellerCard stars={4} title="SANTA PROVO" imgName="7" recomendationsQuantity={3190} />
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col className="text-center">
                            <a href="/burgers-extras" className="btn btn-view-all">VER TODAS</a>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="also-know py-5">
                <Container className="py-lg-5">
                    <Row>
                        <Col lg="4" className="pb-4 pb-lg-0 text-center text-lg-start">
                            <h2>TAMBIÉN ENCONTRÁ</h2>
                        </Col>
                        <Col md="6" lg="4" className="mb-4 mb-md-0">
                            <a href="/entradas-ensaladas" className="linked-card">
                                <p>ENTRADAS Y<br className="d-none d-md-block" /> ENSALADAS</p>
                                <span>> Ver todos</span>
                            </a>
                        </Col>
                        <Col md="6" lg="4">
                            <a href="/bebidas-postres" className="linked-card">
                                <p>BEBIDAS</p>
                                <span>> Ver todos</span>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="we-are pt-5 mt-0 mt-md-5" id="nosotros">
                <Container>
                    <Row>
                        <Col md="2" className="d-flex flex-md-column justify-content-between align-items-center py-5">
                            <FlyingCakeIcon/>
                            <BurgerIcon/>
                        </Col>
                        <Col md="8" className="text-center somos-hutch">
                            <h3 className="mb-4">SOMOS HUTCH</h3>
                            <p>Somos sinónimo de sabor: ofrecemos hamburguesas irresistibles, en un ambiente cómodo, para que te sientas como en casa.</p>
                            <PeopleIcon/>
                        </Col>
                        <Col md="2" className="d-none d-md-flex flex-column justify-content-between align-items-center py-3">
                            <BurgerIcon/>
                            <FlyingCakeIcon/>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </main>
    );
}

export default Home;