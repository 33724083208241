import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import { Container, Row, Col } from 'reactstrap';

const CardSideDishes = ({ title, products, type}) => (
    <Container tag="article" className={`p-5 mt-3 mt-md-5 card card-${type}`}>
        <Row>
            <Col className="text-center text-md-start">
                <h2>{title}</h2>
            </Col>
        </Row>
        <Row>
            {
                products.map(product => product.active === "1" && (
                    <Col tag="article" className="col-12 col-md-6 pt-3">
                        <Row className="text-center text-md-start">
                            <Col md={type === 'salads' || type === 'desserts' ? '12' : '7'}>
                                <p className="product-title mb-0">{product.title}</p>
                                {product.description && (
                                    <p className="product-description">{product.description}</p>
                                )}
                            </Col>
                            <Col md={type === 'salads' || type === 'desserts' ? '12' : '5'}>
                                <p className="price-tag">${product.price}</p>
                            </Col>
                        </Row>
                    </Col>
                ))
            }
        </Row>
    </Container>
);

CardSideDishes.prototype = {
    title: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(Object).isRequired,
    type: PropTypes.string.isRequired
}

export default CardSideDishes;