import * as React from "react"
const SvgComponent = ({width = 120, height = 60}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} data-name="Layer 1" viewBox="0 0 117.19 58.36">
        <path
            d="M3.95 27.15c.09-.31.52-.46.71-.3.62.32 1.66.49 2.89.58 1.88-6.14 4.4-15.95 5.25-18.98 0 0 1.02-4.2 1.98-4.27 1.04-.07 2.38.73 1.21 4.62-.82 2.64-4.21 14.15-5.57 18.74 2.83-.05 6.1-.24 8.56-.37 4-10.88 8.64-22.69 8.06-26.21-.05-.39.3-.78.79-.85 2.04-.29 6.61-.39 5.36 4.35-.66 2.65-4.29 13.06-7.42 23.09 3.19.9 2.04 3.07 1.26 4.15-.12.15-.46.23-.57.07-.3-.32-.94-.56-1.73-.8-2.19 7.23-3.84 13.69-3.72 16.34.06.62-.61 1.32-1.33 1.39-3.03.2-7.31-1.57-3.87-12.45.6-1.87 1.3-3.81 2-5.75-2.89-.03-5.98.09-8.36.22-2.56 8.55-4.61 15.32-4.83 16.1-.42 1.79-1.44 1.78-1.44 1.78-1.61.45.42-8.19 3.36-17.83-3.26-.12-3.01-2.38-2.59-3.62ZM30.12 1.16c.4.24.66.71.93 1.42.69 1.18 1.1.56 1.1.56.61-1.32-2.03-1.97-2.03-1.97ZM59.42 27.48c.2.39.4 1.1-.12 2.11 0 0-9.38 17.53-16.86 18.22 0 0-5.25.56-3.38-5.81.5-1.79 1.21-4.04 2.1-6.37-2.46 3.48-5.6 6.57-8.79 6.22 0 0-4.7-.29-2.63-6.82 1.45-4.9 2.9-8.94 3.41-11.59.18-.93.29-1.32.25-1.71 0-.31.35-.7.66-.69 2.58-.28 5.45.38 4.05 4.26-2 5.44-4.59 11.89-2.62 12.69 1.7.64 5.18-4.23 9.3-13.63.33-.62 1.48-2.48 3.59-1.91 1.72.57 3.24 1.83 2.2 4.71-2 5.44-6.26 15.07-4.27 15.79 1.7.64 6.38-5.7 12.54-15.38.14-.23.46-.23.57-.07Zm-23.63-4.8c-.18.08 1.4.87 1.29 2.67 0 0 1.68-3.18-1.29-2.67Zm12.98 1.48c.29.08.11 3.51.82 2.12.63-1.4-.82-2.12-.82-2.12Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
        <path
            d="M73.61 29.75s-9.38 17.53-16.86 18.22c0 0-5.25.56-3.46-5.81.55-1.94 1.5-4.51 2.38-7.07 1.92-5.44 5-13.36 7.15-19.89-4.16.03-4.45-.05-4.49-.75 0 0 .04-1.25 1.41-1.39.57-.07.8-.07 3.77-.03l.72-2.57c.68-2.41 1.01-4.44 1.05-5.68.02-.62.66-1.24 1.34-1.15 2.06.18 5.54.92 5.22 4.27-.04.7-.66 2.65-1.62 5.21 1.07.09 1.93.1 2.32.1 1.93.1 1.71.88 1.47 1.19-.17.31-.8.62-4.51.73-1.98 5.67-5.05 13.52-7.77 20.43-1.38 3.81-2.24 7.15-1.1 7.56 1.7.64 6.3-5.7 12.54-15.38.14-.23.46-.23.57-.07.2.39.32 1.1-.12 2.11ZM69.45 7.46c.43 1.25 1.02.56 1.02.56.68-1.32-1.6-2.13-1.6-2.13.3.32.31.86.58 1.57Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
        <path
            d="M73.34 45.98c-6.59-1.64-3.9-10.66-2.53-14.15 1.47-3.57 4.74-7.67 8.4-8.72 5.55-1.65 6.54 3.2 6.54 3.2.35 1.25-.65 2.33-2.71 3.25-.28.15-.6-.09-.52-.4.26-1.48.64-5.07-1.28-4.07-1.09.53-3.59 4.72-5.51 8.75 0 0-3.74 8.31-.65 9.59 4.49 1.85 9.79-4.18 9.79-4.18 3.07-3.4 5.44-7.12 7.92-11.22.17-.31.58-.38.72-.07.13.39.18 1.02-.21 1.87 0 0-2.82 6.13-6.65 10.3 0 0-6.2 7.58-13.31 5.85Zm10.81-20.4c.25.78.52 1.49.4 2.19 1.35-1.47-.4-2.19-.4-2.19Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
        <path
            d="M99.59 46.52c-4.08-1.92 2.92-14.87 4.19-17.74 1.19-2.56.65-2.88.65-2.88-2.5-1.12-9.9 13.23-12.64 18.82-.61 1.32-1.16 2.17-1.55 2.17 0 0-3.33.98-2.67-4.71.63-5.3 6.48-20.6 9.05-31.42.61-2.72.85-4.98.79-6.16-.05-.39.37-.78.76-.77 1.87.02 6.21.46 5.99 4.05-.16 2.26-4.07 13.69-8.03 24.72 2.96-4.96 6.69-9.6 10.02-9.48 4.01.2 1.97 4.71 1.97 4.71-2 5.44-6.18 15.07-4.2 15.79 1.7.64 6.28-5.62 12.44-15.31.14-.23.56-.31.65-.07.18.47.32 1.1-.1 2.03 0 0-5.13 9.62-10.92 14.93 0 0-3.11 3.01-6.4 1.33Zm1.53-40.64c.32.24.38.86.68 1.49.51 1.25 1.12.48 1.12.48.53-1.32-1.8-1.97-1.8-1.97Zm4.63 18.39c-.18.08 1.48.88 1.37 2.67 0 0 1.68-3.18-1.37-2.67ZM100.89 51.7c-.72-.04-9.33-.49-14.06-.65-9.38-.31-18.77-.33-28.16-.33s-18.77.1-28.15.25c-8.67.14-17.34.33-26.01.53-1.29.03-2.71.12-3.61 1.04-.73.75-.92 2.54-.9 3.58.02.94.38 2.1 1.31 2.23.6.08 1.14-.31 1.64-.65 3.64-2.42 8.23-2.55 12.4-2.85 5.24-.38 10.2-.23 15.29-.52 9.36-.52 18.72-.76 28.08-1.15 9.36-.38 18.73-.5 28.1-.58 2.83-.02 7.11-.38 9.61 1.36.77.53 1.45 1.02 2.44 1.16 1.1.16 1.69-.18 2.37-.85 1.53-1.5 3.63-1.74 5.7-1.72.04-.1 9.98.05 10.03-.05-2.05-.24-18.08-.92-16.08-.81Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
    </svg>
)
export default SvgComponent