import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import StarIcon from "../Icons/StarIcon";

const BestSellerCard = ({ stars, title, imgName, recomendationsQuantity }) => (
    <section className="text-center best-seller-card p-4">
        <h3>{title}</h3>
        <img src={`images/burgers/${imgName}.png`} alt={title} title={title} className="img-fluid"/>
        <article className={`stars-box stars-${stars}`}>
            <StarIcon/>
            <StarIcon/>
            <StarIcon/>
            <StarIcon/>
            <StarIcon/>
        </article>
        <p>{recomendationsQuantity} Recomendaciones</p>
    </section>
)

BestSellerCard.propTypes = {
    stars: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    imgName: PropTypes.string.isRequired,
    recomendationsQuantity: PropTypes.number.isRequired
};

export default BestSellerCard;