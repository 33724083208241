import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import { Row, Col } from "reactstrap";

import FriesIcon from "../Icons/FriesIcon";

const CardBurger = ({ burger }) => {
    const { id, title, description, price_single, price_double, image } = burger;

    return (
        <Col lg="6" className={`burger-card ${id === "12" && 'special-card'}`}>
            <Row className="p-4">
                <Col xs="12" md="5" className="text-center text-md-start">
                    <img src={`images/burgers/${image}.png`} alt={title} title={title} className="img-fluid"/>
                </Col>
                <Col xs="12" md="7" className="text-center text-md-start">
                    <h4 className="burger-title mb-0">{title} + <FriesIcon/></h4>
                    <p className="burger-description">{description}</p>
                    <Row className="burger-prices">
                        {price_single && (
                            <Col>
                                <p className="mb-1">SIMPLE</p>
                                <p className="price-tag">${price_single}</p>
                            </Col>
                        )}
                        {price_double && (
                            <Col>
                                <p className="mb-1">DOBLE</p>
                                <p className="price-tag">${price_double}</p>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Col>
    );
}

CardBurger.prototype = {
    burger: PropTypes.object.isRequired
};

export default CardBurger;