import React, {useEffect, useState} from 'react';
import axios from "axios";

import CardSideDishes from "../../components/CardSideDishes/CardSideDishes";
import CardSideDishesSpecial from "../../components/CardSideDishesSpecial/CardSideDishesSpecial";
import Banner from "../../components/Banner/Banner";

const Desserts = () => {
    const [desserts, setDesserts] = useState([]);
    const [drinks, setDrinks] = useState([]);

    useEffect(() => {
        const fetchDessertsData = async () => {
            const response = await axios.get("https://hutch.com.ar/api/desserts.php");
            setDesserts(response.data);
        }

        const fetchDrinkssData = async () => {
            const response = await axios.get("https://hutch.com.ar/api/drinks.php");
            setDrinks(response.data);
        }

        fetchDessertsData()
        fetchDrinkssData()
    }, [])

    return (
        <main>
            <Banner page="desserts" title="BEBIDAS" />
            {drinks.length > 0 && (
                <CardSideDishesSpecial
                    title="BEBIDAS"
                    products={drinks}
                    type="drinks"
                />
            )}
            {desserts.length > 0 && (
                <CardSideDishes
                    title="POSTRES"
                    products={desserts}
                    type="desserts"
                />
            )}
        </main>
    );
}

export default Desserts;