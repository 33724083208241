import React from 'react';

import './styles.scss';

import { Container, Row, Col } from 'reactstrap';
import LogoIcon from '../Icons/LogoIcon';
import InstagramIcon from '../Icons/InstagramIcon';
import WhatsappIcon from '../Icons/WhatsappIcon';
import WhatsappFloatIcon from '../Icons/WhatsappFloatIcon';

const Footer = () => {
    return (
        <Container fluid tag="footer" className="py-5">
            <div id="wpp-float">
                <a href="https://api.whatsapp.com/send?phone=541134962752" target="_blank" className="me-3"><WhatsappFloatIcon/></a>
            </div>
            <Container tag="section">
                <Row>
                    <Col tag="article" lg="4" className="text-center text-lg-start">
                        <LogoIcon/>
                    </Col>
                    <Col tag="article" lg="4"
                         className="d-flex flex-column flex-lg-row align-items-center py-4 py-lg-0">
                        <div className="pe-0 pe-md-3">
                            <a href="https://www.instagram.com/hutch.cj/" target="_blank"
                               rel="noreferrer"><InstagramIcon/></a>
                            <a href="https://api.whatsapp.com/send?phone=541134962752" target="_blank" rel="noreferrer"
                               className="ps-2"><WhatsappIcon/></a>
                        </div>
                        <p className="mb-0"><a href="mailto:hutch.rrhh@gmail.com"><strong>TRABAJÁ CON NOSOTROS</strong></a>
                        </p>
                    </Col>
                    <Col tag="article" lg="4"
                         className="d-flex flex-column align-items-center align-items-lg-end justify-content-center">
                        <a href="/burgers-extras">MENÚ</a>
                        <a href="/#nosotros">NOSOTROS</a>
                        <a href="/hace-tu-pedido">HACÉ TU PEDIDO</a>
                        <a href="/terminos-y-condiciones">TÉRMINOS Y CONDICIONES</a>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Footer;