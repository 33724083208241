import React, {useEffect, useState} from "react";
import axios from "axios";

import './styles.scss';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Container
} from 'reactstrap';
import Logo2Icon from "../Icons/Logo2Icon";

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [categories, setCategories] = useState([]);

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get("https://hutch.com.ar/api/categories.php");
            setCategories(response.data);
        }
        fetchData()
    }, [])

    const activeDelivery = window.location.pathname === '/hace-tu-pedido';
    const activeBurgers = window.location.pathname === '/burgers-extras';
    const activeEntrances = window.location.pathname === '/entradas-ensaladas';
    const activeDrinks = window.location.pathname === '/bebidas-postres';

    return (
        <Container fluid tag="header" className="py-3">
            <Navbar expand="md" container="md">
                <NavbarBrand href="/"><Logo2Icon/></NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ms-auto text-center text-md-start" navbar>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className={(activeBurgers || activeEntrances || activeDrinks) && 'dropdown-active'}>
                                MENÚ
                            </DropdownToggle>
                            <DropdownMenu left className="text-center text-md-start">
                                {
                                    categories.map(category => (
                                        <DropdownItem>
                                            <NavLink href={category.category_link}>
                                                {category.category_name}
                                            </NavLink>
                                        </DropdownItem>
                                    ))
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <NavItem className="px-0 px-md-4">
                            <NavLink href="/#nosotros">NOSOTROS</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/hace-tu-pedido" active={activeDelivery}>
                                HACÉ TU PEDIDO
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </Container>
    );
}

export default NavBar;