import React from 'react';

import './styles.scss';

import {Col, Container, Row} from "reactstrap";

const BannerHome = () => (
    <Container fluid tag="section" className="banner-home">
        <Row className="h-100 align-items-center">
            <Col md="5" className="text-center text-md-end">
                <img src="images/animated-logo-no-loop.gif" alt="HUTCH logo" title="HUTCH logo" className="img-fluid"/>
            </Col>
            <Col md="7" className="text-center text-md-start">
                <h1>LAS MEJORES <br className="d-none d-md-block"/>
                    HAMBURGUESAS <br className="d-none d-md-block"/>
                    DEL BARRIO</h1>
                <a href="/burgers-extras" className="btn btn-view-menu">VER MENÚ</a>
            </Col>
        </Row>
    </Container>
)


export default BannerHome;