import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from './pages/home/home';
import Burgers from './pages/burgers/burgers';
import Entrances from './pages/entrances/entrances';
import Desserts from './pages/desserts/desserts';
import Delivery from './pages/delivery/delivery';
import Terms from './pages/terms/terms';

function App() {
  return (
      <Router>
        <Routes>
          <Route exact path="/" element={ <Home/> }/>
          <Route exact path="/burgers-extras" element={ <Burgers/> }/>
          <Route exact path="/entradas-ensaladas" element={ <Entrances/> }/>
          <Route exact path="/bebidas-postres" element={ <Desserts/> }/>
          <Route exact path="/hace-tu-pedido" element={ <Delivery/> }/>
          <Route exact path="/terminos-y-condiciones" element={ <Terms/> }/>
        </Routes>
      </Router>
  )
}

export default App;