import * as React from "react"
const SvgComponent = ({width = 58, height = 56}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} data-name="Layer 1" viewBox="0 0 57.14 55.19">
        <path
            d="M3.83 17.17c0 .64.01 1.24 0 1.83-.03 1.09-.84 1.92-1.88 1.93-1.06.01-1.92-.82-1.93-1.94-.02-2.59-.05-5.18.01-7.77C.1 8.2 2.46 5.84 5.48 5.73c1.75-.06 3.51-.02 5.26-.02h.71c0-1.23-.01-2.38 0-3.53 0-.35.03-.73.16-1.05.34-.8 1.19-1.24 2.05-1.11.84.13 1.52.83 1.56 1.72.05 1.15.04 2.31.02 3.46 0 .39.13.51.52.51 3.51-.01 7.01 0 10.52 0 .11 0 .23-.03.39-.05V2.12C26.68.86 27.46 0 28.59 0c1.11 0 1.88.86 1.89 2.09v3.57h11.37c.01-.2.04-.41.04-.62 0-1.02-.01-2.03 0-3.05.02-1.19.83-2 1.93-1.99 1.09.01 1.86.84 1.87 2.04v3.63c.26.01.47.03.68.03h4.78c3.56.02 5.97 2.41 5.97 5.96v9.14c0 .95-.44 1.64-1.23 1.93-.76.28-1.51.18-2.02-.45-.3-.37-.51-.91-.54-1.39-.07-1.23-.02-2.46-.02-3.74H3.83Zm38.02-7.62H15.29v3.73h26.56V9.55ZM3.84 13.26h7.57V9.54c-2.03 0-4.02-.04-6 .02-.84.02-1.5.79-1.55 1.63-.04.67 0 1.35 0 2.06Zm41.88.01h7.61v-1.63c0-1.39-.74-2.13-2.14-2.14h-4.9c-.19 0-.38.03-.58.04v3.72Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
        <path
            d="M57.1 38.03c.04 9.42-7.65 17.14-17.12 17.16-9.39.02-17.09-7.62-17.14-17.01-.05-9.48 7.58-17.2 17.03-17.24 9.5-.04 17.19 7.58 17.23 17.1Zm-30.44 0c-.01 7.37 5.95 13.36 13.31 13.36 7.32 0 13.31-5.96 13.34-13.3.03-7.34-5.96-13.34-13.33-13.34-7.35 0-13.3 5.93-13.32 13.28ZM.02 36.78V26.8c0-.8.31-1.43 1.03-1.82.63-.34 1.27-.32 1.87.06.67.41.92 1.05.92 1.83-.01 3.79 0 7.57 0 11.36v7.89c0 1.61.69 2.3 2.28 2.3h14.59c1.08 0 1.76.44 2.04 1.31.36 1.09-.33 2.24-1.46 2.44-.23.04-.48.05-.71.05H6.05c-2.96 0-5.18-1.65-5.84-4.33-.13-.53-.18-1.1-.18-1.65-.02-3.15 0-6.3 0-9.44Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
        <path
            d="M13.33 39.96c-.94 0-1.87.01-2.81 0-1.12-.02-1.92-.79-1.95-1.84-.03-1.07.77-1.93 1.9-1.95 1.89-.03 3.78-.03 5.67 0 1.14.02 1.93.86 1.92 1.94-.02 1.06-.84 1.84-1.99 1.86-.92.01-1.83 0-2.75 0ZM13.37 24.75h2.75c1.08.01 1.9.77 1.95 1.78.05 1.06-.68 1.97-1.78 2-1.99.06-3.98.06-5.97 0-1.07-.03-1.79-.94-1.74-1.97.04-1.03.84-1.78 1.93-1.8.96-.01 1.91 0 2.87 0ZM13.34 34.24c-.94 0-1.87.01-2.81 0-1.15-.02-1.96-.81-1.96-1.89 0-1.06.77-1.87 1.9-1.89 1.91-.03 3.82-.03 5.73 0 1.11.02 1.88.85 1.87 1.91-.01 1.05-.81 1.83-1.93 1.86-.94.02-1.87 0-2.81 0ZM38.06 34.22c0-1.25-.02-2.51 0-3.76.02-1.05.72-1.8 1.7-1.91.91-.1 1.76.44 2.01 1.33.08.26.08.55.09.82 0 1.85.03 3.7-.01 5.55-.01.5.18.73.6.94 1.43.69 2.85 1.41 4.27 2.13 1.11.56 1.52 1.62 1.04 2.62-.49 1.01-1.62 1.34-2.75.77-1.91-.95-3.8-1.91-5.71-2.85-.85-.41-1.24-1.07-1.24-2v-3.64Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
    </svg>
)
export default SvgComponent
