import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import GuyEatingIcon from "../Icons/GuyEatingIcon";

import { Container, Row, Col } from 'reactstrap';

const CardExtras = ({ extras }) => (
    <Container tag="section" className="px-5 pt-4 pt-md-0 mt-3 mt-md-5 card-extras">
        <Row className="align-items-center">
            <Col md="7" className="text-center text-md-start">
                <h2>EXTRAS</h2>
                {
                    extras.map(extra => extra.active === "1" && (
                        <Row tag="article" className="pt-2 pt-lg-3">
                            <Col md="7">
                                <p className="extra-title mb-0">{extra.title}</p>
                                {extra.description && (
                                    <p className="extra-description">{extra.description}</p>
                                )}
                            </Col>
                            <Col md="5">
                                <p className="price-tag">${extra.price}</p>
                            </Col>
                        </Row>
                    ))
                }
            </Col>
            <Col md="5" className="text-center">
                <GuyEatingIcon/>
            </Col>
        </Row>
    </Container>
);

CardExtras.prototype = {
    extras: PropTypes.arrayOf(Object).isRequired
}

export default CardExtras;