import * as React from "react"
const SvgComponent = ({width = 25, height = 25}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} data-name="Layer 1" viewBox="0 0 24.73 23.52">
        <path
            d="m12.37 1.13 3.49 7.07 7.8 1.13-5.65 5.51 1.33 7.77-6.97-3.67-6.98 3.67 1.33-7.77-5.65-5.51L8.88 8.2l3.49-7.07z"
            style={{
                fill: "none",
                stroke: "#f5ca00",
                strokeMiterlimit: 10,
            }}
        />
    </svg>
)
export default SvgComponent
