import React from 'react';
import { ElfsightWidget } from 'react-elfsight-widget';

import './styles.scss';

import { Container, Row, Col } from "reactstrap";

import Banner from '../../components/Banner/Banner';
import LocationIcon from "../../components/Icons/LocationIcon";
import OpenHoursIcon from "../../components/Icons/OpenHoursIcon";

const Delivery = () => {
    return (
        <main className="delivery">
            <Banner page="delivery"/>
            <Container fluid tag="section" className="green-bg links">
                <Row>
                    <Col xs="12" tag="article" className="text-center py-3 py-md-4 border-t">
                        <a href="https://api.whatsapp.com/send?phone=1134962752" target="_blank" rel="noreferrer">
                            <h2>WHATSAPP / TAKE AWAY</h2>
                        </a>
                    </Col>
                    <Col xs="12" tag="article" className="text-center py-3 py-md-4 border-y">
                        <a href="https://www.pedidosya.com.ar/restaurantes/3-de-febrero/hutch-hamburguesas-5341718d-418d-426f-a14a-c582d645a19b-menu?origin=shop_list" target="_blank" rel="noreferrer">
                            <h2>PEDIDOS YA</h2>
                        </a>
                    </Col>
                    <Col xs="12" tag="article" className="text-center py-3 py-md-4">
                        <a href="https://www.instagram.com/hutch.cj/" target="_blank" rel="noreferrer">
                            <h2>VISITÁ NUESTRO INSTAGRAM</h2>
                        </a>
                    </Col>
                </Row>
            </Container>
            <Container tag="section" className="py-5">
                <Row className="align-items-center">
                    <Col md="6" tag="article">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.9977993111634!2d-58.59748982346315!3d-34.60421715752599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb933bfae25a3%3A0x627753bb721a72d7!2sRosetti%206261%2C%20B1684BZC%20Cdad.%20Jardin%20Lomas%20de%20Palomar%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1702418434521!5m2!1ses!2sar"
                            allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className="map" title="Direccion del local"></iframe>
                    </Col>
                    <Col md="6" tag="article" className="local-information text-center text-md-start">
                        <div className="my-5 mt-md-0">
                            <LocationIcon/>
                            <p><strong>Rosetti 6261, Ciudad Jardín,</strong><br/>Lomas del Palomar, CP: 1684</p>
                        </div>
                        <div>
                            <OpenHoursIcon/>
                            <p className="mb-0"><strong>Martes a domingos</strong></p>
                            <p>11:30 a 15hs y de 19 a 23:30hs.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container tag="section" className="py-2 py-md-5">
                <Row>
                    <Col xs="12" tag="article" className="text-center instagram-follow">
                        <h3 className="mb-0">EL GUSTO ES NUESTRO</h3>
                        <p>¡Seguinos!</p>
                    </Col>
                    <Col xs="12" tag="article">
                        <ElfsightWidget widgetId="b747671a-7a9c-4fc3-a527-967869089e1d" lazy modern />
                    </Col>
                </Row>
            </Container>
        </main>
    );
}

export default Delivery;