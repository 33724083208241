import * as React from "react"
const SvgComponent = ({width = 94, height = 87}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} data-name="Layer 1" viewBox="0 0 93.18 84.95">
        <path
            d="M83.34 48.42 20.98 17.27c-3.26-1.63-4.76-5.24-3.34-8.07C22.59-.72 44.95-3.97 65.43 6.26S97.3 35.21 91.8 46.24c-1.41 2.83-5.2 3.8-8.46 2.18Z"
            style={{
                fill: "#d97533",
                strokeWidth: 0,
            }}
        />
        <path
            d="M91.8 46.24c-.71 1.42-2.01 2.37-3.54 2.76-1.53.39-3.29.23-4.92-.58L20.98 17.27c-3.26-1.63-4.76-5.24-3.34-8.07.2-.39.42-.78.67-1.15l60.78 30.36c1.63.81 3.39.98 4.92.58 1.53-.39 2.83-1.34 3.54-2.76 1.45-2.91 1.73-6.35.97-10.03 4.6 7.26 6 14.59 3.28 20.04Z"
            style={{
                fill: "#d97533",
                strokeWidth: 0,
            }}
        />
        <path
            d="M91.8 46.24c-.71 1.42-2.01 2.37-3.54 2.76-1.53.39-3.29.23-4.92-.58L20.98 17.27c-3.26-1.63-4.76-5.24-3.34-8.07.2-.39.42-.78.67-1.15l.18.09c-.3 2.46 1.18 5.1 3.84 6.43l62.36 31.15c1.63.81 3.39.98 4.92.58 1.14-.29 2.16-.9 2.89-1.78-.19.59-.43 1.16-.7 1.71ZM75.15 79.57c-.09.18-.19.36-.29.53-2.75 4.77-9.42 6.23-15.09 3.4L7.08 57.19C1.3 54.3-1.53 47.92.84 42.84l.15-.3c.31-.63.74-1.16 1.25-1.6a5.74 5.74 0 0 1 2.28-1.16c1.53-.39 3.29-.23 4.92.58L71.8 71.51c2.54 1.27 4.01 3.74 3.87 6.11-.04.67-.21 1.34-.52 1.96Z"
            style={{
                fill: "#d97533",
                strokeWidth: 0,
            }}
        />
        <path
            d="M75.67 77.61 2.24 40.94a5.74 5.74 0 0 1 2.28-1.16c1.53-.39 3.29-.23 4.92.58L71.8 71.51c2.54 1.27 4.01 3.74 3.87 6.11Z"
            style={{
                fill: "#d97533",
                strokeWidth: 0,
            }}
        />
        <path
            d="M75.15 79.57c-.09.18-.19.36-.29.53-2.75 4.77-9.42 6.23-15.09 3.4L7.08 57.19C1.87 54.59-.94 49.15.29 44.37a13.51 13.51 0 0 0 4.9 4.12L57.88 74.8c5.45 2.72 11.8 1.49 14.74-2.84 2.69 1.78 3.83 5.02 2.54 7.6Z"
            style={{
                fill: "#d97533",
                strokeWidth: 0,
            }}
        />
        <rect
            width={87.4}
            height={10.89}
            x={-0.64}
            y={45.62}
            rx={3.84}
            ry={3.84}
            style={{
                fill: "#35160c",
                strokeWidth: 0,
            }}
            transform="rotate(26.54 43.06 51.055)"
        />
        <path
            d="m82.85 69.19-1.39 2.79c-.96 1.91-3.42 2.64-5.48 1.61L5.28 38.28c-2.06-1.03-2.96-3.43-2.01-5.35l.2-.39c.05.03.11.06.17.09l70.7 35.31c2.06 1.03 4.53.31 5.48-1.61l1.2-2.39c1.94 1.07 2.77 3.4 1.84 5.26Z"
            style={{
                fill: "#35160c",
                strokeWidth: 0,
            }}
        />
        <rect
            width={87.36}
            height={6.74}
            x={6.97}
            y={32.49}
            rx={2.38}
            ry={2.38}
            style={{
                fill: "#b81716",
                strokeWidth: 0,
            }}
            transform="rotate(26.54 50.644 35.86)"
        />
        <path
            d="m90.17 54.49-.89 1.78c-.58 1.17-2.11 1.6-3.39.96l-73.5-36.71c-1.28-.64-1.85-2.12-1.26-3.29l.19-.38 73.29 36.6c1.28.64 2.8.2 3.39-.96l.7-1.4.22.11c1.28.64 1.85 2.12 1.26 3.29Z"
            style={{
                fill: "#b81716",
                strokeWidth: 0,
            }}
        />
        <path
            d="m11.63 27.45 19.84 20.18 47.72 14.71 2.37-1.83-69.19-34.55-.74 1.49z"
            style={{
                fill: "#f6cb00",
                strokeWidth: 0,
            }}
        />
        <path
            d="m81.56 60.51-2.37 1.83-47.72-14.71-19.84-20.18.74-1.49 5.44 2.72 15.34 15.59 41.49 12.79 6.92 3.45z"
            style={{
                fill: "#f6cb00",
                strokeWidth: 0,
            }}
        />
        <path
            d="M85.42 58.94c.43-.86.22-1.85-.46-2.19L13.34 20.99c-.68-.34-1.6.08-2.03.94l-.86 1.72c-.03.07-.06.13-.09.2a.95.95 0 0 0-.1.17c-1.01 2.02 2.32 5.73 7.44 8.29 4.06 2.03 8.02 2.72 9.99 1.91l1.01.51c.54 2.06 3.47 4.81 7.53 6.84s8.02 2.72 9.99 1.91l1.01.51c.54 2.06 3.47 4.81 7.53 6.84 4.06 2.03 8.02 2.72 9.99 1.91l1.01.51c.54 2.06 3.47 4.81 7.53 6.84 5.12 2.56 10.08 2.99 11.09.97.03-.06.05-.12.08-.18.04-.06.08-.12.11-.19l.86-1.72Z"
            style={{
                fill: "#00553c",
                strokeWidth: 0,
            }}
        />
        <path
            d="M84.56 60.67c-.03.07-.07.13-.11.19-.02.06-.05.12-.08.18-1.01 2.02-5.97 1.59-11.09-.97-4.06-2.03-6.99-4.78-7.53-6.84l-1.01-.51c-1.97.81-5.93.11-9.99-1.91s-6.99-4.78-7.53-6.84l-1.01-.51c-1.97.81-5.93.11-9.99-1.91-4.06-2.03-6.99-4.78-7.53-6.84l-1.01-.51c-1.97.81-5.93.11-9.99-1.91-5.12-2.56-8.45-6.26-7.44-8.29.03-.06.06-.11.1-.17.03-.07.05-.13.09-.2l.31-.63c.94 1.95 3.66 4.32 7.26 6.12 4.06 2.03 8.02 2.72 9.99 1.91l1.01.51c.54 2.06 3.47 4.81 7.53 6.84 4.06 2.03 8.02 2.72 9.99 1.91l1.01.51c.54 2.06 3.47 4.81 7.53 6.84 4.06 2.03 8.02 2.72 9.99 1.91l1.01.51c.54 2.06 3.47 4.81 7.53 6.84 5.12 2.56 10.08 2.99 11.09.97.03-.06.05-.12.08-.18.04-.06.07-.12.11-.19l.3-.61c.5.43.62 1.28.24 2.04l-.86 1.72Z"
            style={{
                fill: "#00553c",
                strokeWidth: 0,
            }}
        />
        <path
            d="M26.93 7.87c-.23-.6.08-1.28.7-1.48 1.26-.41 3.5-1.07 6.34-1.58 3.8-.68 9.39-1.19 15.02.04.62.14 1.01.76.85 1.38-.15.58-.73.94-1.32.81-8.58-1.88-17.29.54-20.19 1.48-.57.18-1.18-.11-1.39-.66ZM21.64 11.37c-.54-.42-.57-1.22-.08-1.69.5-.48 1.13-1 1.79-1.42.62-.39 1.45-.09 1.67.61.15.49-.03 1.02-.46 1.29-.49.31-1.01.74-1.44 1.15-.41.39-1.03.42-1.48.07Z"
            style={{
                fill: "#fff",
                strokeWidth: 0,
            }}
        />
    </svg>
)
export default SvgComponent
