import React from 'react';

import './styles.scss';

import { Container, Row, Col } from 'reactstrap';

const Terms = () => (
    <Container fluid className="py-5">
        <Container>
            <Row>
                <Col className="text-center">
                    <h1>TERMINOS Y CONDICIONES.</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Cuando usted ingresa a nuestro sitio web <a href="www.hutch.com.ar">www.hutch.com.ar</a> y/o realiza un pedido y/o efectúa una compra en nuestro local está aceptando de conformidad los términos y condiciones de nuestra empresa y su política de privacidad aquí detallados.</p>
                    <p><strong>“HUTCH”</strong> es marca registrada. Las fotografías, gráficos, ilustraciones que se publican en nuestro sitio web y/o en el local comercial, son propiedad exclusiva de <strong>“HUTCH”</strong>, quedando expresamente prohibido su publicación y/o difusión por personales ajenas a la empresa sin autorización expresa y documentada.</p>
                    <p>Los contenidos, fotografías e ilustraciones publicadas en nuestro sitio web son de carácter ilustrativo y no generan ninguna obligación contractual.</p>
                    <p>Cualquier promoción publicada en nuestra página web o en nuestras redes sociales no se acumulará con otras promociones y/u otros beneficios ni descuentos corporativos y se limitarán a los términos y condiciones indicados en cada promoción.</p>
                    <p>Las promociones publicadas pueden sufrir modificaciones sin previo aviso y sin que ello genere derecho a reclamo alguno.</p>
                    <p>Ante cualquier duda o consulta podrá contactarnos enviando un correo electrónico a <a href="mailto:infohutchbar@gmail.com">infohutchbar@gmail.com</a>.</p>
                    <p><strong>INFORMACION AL CONSUMIDOR.</strong></p>
                    <p>Si usted padece algún tipo de alergia o cualquier tipo de exigencia o necesidad alimentaria deberá informarlo a nuestro personal antes de realizar su pedido para que podamos brindarle la información completa acerca de los ingredientes y procedimientos de elaboración de nuestros productos.</p>
                    <p>Nuestro menú vegetariano consiste en un medallón a base de plantas elaborado y certificado con altos estándares de calidad y trazabilidad.</p>
                    <p>Nuestro menú apto para celiacos consiste en una hamburguesa de carne vacuna “al plato” sin TACC. Opcionalmente podrá solicitar panificados sin TACC envasados “al vacío” provisto por empresas certificadas y con trazabilidad.</p>
                    <p><strong>“HUTCH”</strong> no cuenta con una cocina adaptada para la elaboración de productos SIN TACC. Es por ello que, a pesar de nuestros altos estándares de seguridad e higiene en la manipulación de los alimentos, no podemos garantizar la inexistencia de contaminación cruzada con gluten a través de superficies, utensilios, vajilla, recipientes, etc. En caso de dudas sobre la seguridad del producto, absténgase de consumir nuestros productos SIN TACC.</p>
                    <p>El tiempo de demora en la expedición y/o envíos de los pedidos podría ser variable y está sujeta a los niveles de demanda de pedidos en el local.</p>
                    <p><strong>AVISO LEGAL DE ZONA VIDEOVIGILADA.</strong></p>
                    <p>Nuestro local posee cámaras de videovigilancia las que se utilizan exclusivamente con fines de seguridad.</p>
                    <p><strong>POLITICA DE PRIVACIDAD.</strong></p>
                    <p>En <strong>“HUTCH”</strong> cumplimos con la normativa de la Ley 25.326 “Ley Nacional de Protección de Datos Personales” e implementamos en nuestro sitio web las medidas de seguridad recomendadas para mantener la privacidad de tus datos. Solo reunimos los datos personales cuando usted los proporciona en forma directa y con su consentimiento expreso e informado, es decir cuando se registra en nuestro sitio, realiza un pedido o completa un formulario y sólo se utilizará con las finalidades detalladas en el presente. Así, solo utilizamos la información que usted nos suministra con fines estadísticos y/o de facturación y/o para informarle acerca de nuevos productos, comunicarnos con usted para responderle consultas o reclamos, ofrecerle promociones y/o mejorar la calidad de nuestro servicio.</p>
                    <p>El cliente es responsable de la autenticidad y veracidad de los datos que suministre a <strong>“HUTCH”</strong>. En ningún caso la información que usted suministre será cedida sin su consentimiento excepto ante cualquier requerimiento de la autoridad judicial o administrativa. Cuando usted nos brinde información estará autorizando a <strong>“HUTCH”</strong> al uso y tratamiento de dicha información conforme los términos de nuestra política de privacidad.</p>
                    <p><strong>“HUTCH”</strong> podrá utilizar la información que usted nos brinde para desarrollar nuevos productos pero sin que esa información comprenda datos que permitieran identificar a la persona del consumidor.</p>
                    <p>En cualquier momento <strong>“HUTCH”</strong> podrá introducir modificaciones en su Política de Privacidad siendo suficiente a los fines de notificación al usuario la publicación en nuestro sitio web.</p>
                    <p>Para cualquier duda o consulta sobre nuestra Política de Privacidad o de Protección de Datos Personales contáctenos a la siguiente dirección de correo electrónico: <a href="mailto:infohutchbar@gmail.com">infohutchbar@gmail.com</a>.
                    </p>
                    <p><strong>CONSULTAS Y RECLAMOS.</strong></p>
                    <p>Para cualquier consulta y/o reclamo puede contactarse con nosotros a la siguiente dirección de correo electrónico: <a href="mailto:infohutchbar@gmail.com">infohutchbar@gmail.com</a>.</p>
                    <p><strong>LEY DE DEFENSA DEL CONSUMIDOR.</strong></p>
                    <p>Ponemos a tu disposición el texto completo de la Ley Nº 24.240 “Ley de Defensa del Consumidor”. (LINK DE ACCESO: <a href="https://servicios.infoleg.gob.ar/infolegInternet/anexos/0-4999/638/texact.htm" target="_blank" rel="noreferrer">https://servicios.infoleg.gob.ar/infolegInternet/anexos/0-4999/638/texact.htm</a>)</p>
                </Col>
            </Row>
        </Container>
    </Container>
);

export default Terms;