import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import {Col, Container, Row} from "reactstrap";

const Banner = ({ page, title }) => {
    return (
        <Container fluid tag="section" className={`py-5 banner banner-${page}`}>
            <Row>
                <Col className="py-0 py-5 text-center d-flex flex-column flex-md-row justify-content-center align-items-center">
                    {page === 'delivery' && (<img src="images/package.png" alt="DISFRUTÁ TU HUTCH EN CASA" title="DISFRUTÁ TU HUTCH EN CASA" className="img-fluid pb-2 pb-md-0"/>)}
                    <h1>{title ? title : 'DISFRUTÁ TU HUTCH EN CASA'}</h1>
                </Col>
            </Row>
        </Container>
    );
}

Banner.propTypes = {
    page: PropTypes.string.isRequired,
    title: PropTypes.string
};

export default Banner;