import React, {useEffect, useState} from 'react';
import axios from "axios";

import { Container, Row } from "reactstrap";

import Banner from "../../components/Banner/Banner";
import CardBurger from "../../components/CardBurger/CardBurger";
import CardExtras from "../../components/CardExtras/CardExtras";

const Burgers = () => {
    const [burgers, setBurgers] = useState([]);
    const [extras, setExtras] = useState([]);

    useEffect(() => {
        const fetchBurgersData = async () => {
            const response = await axios.get("https://hutch.com.ar/api/burgers.php");
            setBurgers(response.data);
        }
        const fetchExtrasData = async () => {
            const response = await axios.get("https://hutch.com.ar/api/extras.php");
            setExtras(response.data);
        }

        fetchBurgersData()
        fetchExtrasData()
    }, [])

    return (
        <main>
            <Banner page="burgers" title="BURGERS" />
            <Container className="pt-3 pt-md-5">
                <Row>
                    {burgers.map(burger => burger.active === "1" && (
                        <CardBurger burger={burger} />
                    ))}
                </Row>
            </Container>
            {extras.length > 0 && (
                <CardExtras extras={extras}/>
            )}
        </main>
    );
}

export default Burgers;