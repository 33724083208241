import * as React from "react"
const SvgComponent = ({width = 50, height = 50}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} data-name="Layer 1" viewBox="0 0 66.22 66.25">
        <path
            d="M31.04 0h4.14c.17.04.33.09.5.11 5.16.39 9.98 1.87 14.38 4.57 8.55 5.26 13.81 12.83 15.66 22.73.22 1.2.34 2.42.51 3.64v4.14c-.04.17-.09.33-.11.5-.39 5.09-1.91 9.81-4.47 14.23-6.6 11.38-19.94 17.89-32.96 16.02-8.85-1.27-16.1-5.4-21.63-12.42C1.59 46.55-.67 38.58.17 29.79c.67-7.05 3.41-13.27 8.11-18.57C13.42 5.43 19.82 1.83 27.47.5c1.18-.21 2.38-.34 3.57-.5Zm21.18 33.13c-.12-3.14-.1-6.32-.39-9.47-.35-3.8-2.23-6.7-5.77-8.36-1.54-.72-3.2-1.05-4.89-1.07-4.48-.06-8.97-.12-13.45-.1-1.63 0-3.29.09-4.89.37-3.87.68-6.61 2.85-7.88 6.62-.41 1.23-.66 2.56-.7 3.86-.12 4.5-.13 9.01-.13 13.51 0 1.38.1 2.76.24 4.13.49 4.81 3.78 8.4 8.57 9.09 2.38.34 4.81.38 7.22.38 4.14 0 8.28.02 12.4-.27 5.13-.36 8.86-4.17 9.29-9.31.26-3.11.26-6.24.38-9.39Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
        <path
            d="M17.46 33.11c.08-2.92.1-5.79.27-8.64.24-3.98 2.76-6.61 6.73-6.71 5.75-.15 11.5-.16 17.25 0 4.06.11 6.66 2.71 6.77 6.79.15 5.77.13 11.55-.01 17.31-.07 2.93-1.54 5.21-4.47 6.17-1.13.37-2.36.55-3.55.57-4.89.06-9.78.07-14.67 0-1.29-.02-2.64-.24-3.85-.68-2.77-1-3.99-3.26-4.18-6.06-.19-2.92-.2-5.86-.28-8.73Zm25.4.01c0-5.39-4.32-9.73-9.71-9.77-5.4-.03-9.79 4.33-9.8 9.75 0 5.38 4.32 9.73 9.71 9.77 5.4.04 9.79-4.33 9.8-9.75Zm2.67-10.19a2.27 2.27 0 0 0-2.27-2.25c-1.28 0-2.29 1.01-2.28 2.3.01 1.28 1.04 2.27 2.33 2.24a2.262 2.262 0 0 0 2.22-2.29Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
        <path
            d="M33.13 39.45c-3.48 0-6.34-2.82-6.36-6.3-.02-3.51 2.84-6.38 6.35-6.37 3.49 0 6.32 2.84 6.32 6.33s-2.83 6.33-6.31 6.34Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
    </svg>
)
export default SvgComponent
