import * as React from "react"
const SvgComponent = ({width = 41, height = 49}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} data-name="Layer 1" viewBox="0 0 40.64 58.75">
        <path
            d="M40.64 20.11c-.16 3.38-1.18 6.47-2.59 9.45-2.37 5-5.6 9.42-9.26 13.53-2.28 2.56-4.73 4.99-7.13 7.44-1.02 1.04-1.76 1.01-2.82 0-5.45-5.17-10.43-10.74-14.35-17.19-1.9-3.12-3.5-6.4-4.16-10.02-.95-5.25.16-10.15 3.23-14.46C8.51 1.91 15.4-1.12 23.8.37c8.31 1.48 13.65 6.66 16.01 14.78.46 1.6.56 3.3.83 4.96ZM20.5 47.52c.08-.13.22-.4.42-.62 2.58-2.85 5.25-5.63 7.72-8.57 2.8-3.33 5.2-6.95 6.98-10.96 1.51-3.41 2.24-6.93 1.36-10.63-1.58-6.65-5.64-11.15-12.22-12.96-6.56-1.8-12.37-.07-17.05 4.89-3.49 3.7-4.99 8.19-4.41 13.28.36 3.15 1.69 5.97 3.24 8.69 2.67 4.7 6.05 8.86 9.73 12.79 1.28 1.37 2.71 2.61 4.24 4.06Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
        <path
            d="M19.85 58.75c-4.6-.1-9.46-.66-14.07-2.61-1.58-.67-3.05-1.53-4.24-2.81-2.07-2.23-2.05-5.08.04-7.29 1.58-1.67 3.58-2.63 5.69-3.38.85-.3 1.61.13 1.91.97.3.83 0 1.58-.85 1.97-.86.4-1.77.72-2.6 1.2-.7.4-1.37.91-1.93 1.49-.91.94-.96 1.96.02 2.82.96.84 2.06 1.58 3.21 2.13 2.55 1.22 5.3 1.74 8.09 2.06 4.02.47 8.04.4 12.04-.26 2.86-.47 5.66-1.19 8.14-2.77.49-.31.96-.71 1.35-1.13.9-.96.9-1.86.03-2.85-.94-1.07-2.19-1.68-3.46-2.25-.37-.17-.77-.29-1.13-.47-.8-.39-1.1-1.11-.81-1.93.3-.84 1.04-1.24 1.9-.99.48.14.94.34 1.4.54 1.59.7 3.09 1.55 4.3 2.84 2.11 2.25 2.13 5.09.01 7.33-1.84 1.95-4.24 2.94-6.74 3.71-3.9 1.21-7.92 1.64-12.32 1.67ZM20.25 12.38c4.34 0 7.88 3.53 7.88 7.85 0 4.31-3.57 7.88-7.87 7.88-4.31 0-7.87-3.56-7.87-7.87 0-4.32 3.53-7.85 7.87-7.86Zm4.72 7.94c.07-2.56-1.99-4.72-4.57-4.79-2.62-.08-4.75 1.93-4.86 4.58-.1 2.56 1.99 4.76 4.61 4.85 2.56.09 4.75-2.01 4.82-4.63Z"
            style={{
                fill: "#00553b",
                strokeWidth: 0,
            }}
        />
    </svg>
)
export default SvgComponent
